<template>
   <div class="enclosure-right" >
              <div
                class="file-box"
                @click="downloadFile(attachment.url)"
              >
                <img class="excel" src="@/assets/img/wang/excel.png" />
                <div class="fill-mame">
                  <span>{{ attachment.name }}</span>
                  <!-- <span>({{ items.size || "99.9KB" }})</span> -->
                </div>
              </div>
            </div>
</template>
 
<script>
export default {
  props: {
    attachment: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    downloadFile(url) {
      let link = document.createElement("a");
      link.href = url;
      link.click();
      link.remove();
    },
  },
};
</script>

<style scoped lang="scss">
   .enclosure-right {
            display: flex;
            flex-wrap: wrap;
            height: auto;
            width: calc(100% - 80px);
            .file-box {
              display: flex;
              align-items: center;
              height: 51px;
              min-width: 170px;
              margin: 0px 30px 16px 0px;
              background: #f5f6fa;
              border-radius: 4px;
              box-sizing: border-box;
              padding-right: 40px;
              cursor: pointer;
              .excel {
                height: 35px;
                width: 30px;
                margin: 0px 8px;
              }
              .fill-mame {
                display: flex;
                flex-direction: column;
                height: auto;
                width: auto;
                span:nth-child(1) {
                  height: 17px;
                  line-height: 17px;
                  font-size: 12px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                }
                span:nth-child(2) {
                  height: 17px;
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 17px;
                }
              }
              .fill-del {
                height: 16px;
                width: 16px;
                margin: 0px 16px 0px 14px;
                cursor: pointer;
              }
            }
          }
</style>