<template>
  <div class="detail-page">
    <div class="detail-header" @click="back">
      <img src="../../assets/img/zhang/back.png" alt="" />
      <span>详情</span>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="platform-info" v-loading="ifloading">
        <p class="texthidden" v-html="headInfo.accountName ? headInfo.accountName : ''"></p>

        <div class="platform-info-middle">
          <div class="platform-info-middle-left">
            <el-popover v-if="headInfo.messageTypeNameList.length > 0" placement="right-start" popper-class="monitor-yt-popover" effect="dark" width="400"
                        trigger="hover">
              <div class="popover">
                <div class="popover-type m-l-16 m-t-16" v-for="(value, inx) in headInfo.messageTypeNameList" :key="inx">
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">{{ value }}</div>
                    <div class="texthidden">{{ value }}</div>
                  </el-tooltip>
                </div>
              </div>
              <div slot="reference">
                <div class="type">
                  {{ headInfo.messageTypeNameList[0] }}
                </div>
              </div>
            </el-popover>
            <div v-else class="greedtag">正常</div>
            <div class="sel-tag">
              <span>标签</span>
              <el-select @change="ChangeFn(headInfo, $event)" class="sel-tag-sel" v-model="headInfo.labelId" placeholder="" clearable>
                <el-option v-for="item in infoLabelList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="disposal">
              处置状态: {{ headInfo.disposalLogoName || "无" }}
            </div>
          </div>
          <div class="platform-info-middle-right">
            <div class="harmful-info" @click="toDetail(4, headInfo)">
              <span>有害信息({{ headInfo.harmfulCnt }})</span>
            </div>
            <div class="detail-middle" @click="toDetail(2, headInfo)">
              <img src="../../assets/img/zhang/detail.png" alt="" />
              <span>账号详情</span>
            </div>
            <div @click="toDetail(3, headInfo)">
              <img src="../../assets/img/zhang/content-info.png" alt="" />
              <span>内容信息</span>
            </div>
          </div>
        </div>
        <div class="platform-info-bottom">
          <div class="content-info">
            <div class="content-info-middle">
              平台类型：{{ headInfo.platformTypeName }}
            </div>
            <div class="content-info-middle texthidden" v-if="headInfo.platformType == 1">
              主办单位：{{ headInfo.companyName || "无" }}
            </div>
            <div class="content-info-middle" v-else>
              作者：{{ headInfo.accountName || "无" }}
            </div>
            <div class="content-info-middle" v-if="headInfo.platformType == 2 || headInfo.platformType == 15">
              联系电话：{{  headInfo.bindPhone || "无" }}
            </div>
            <div class="content-info-middle" v-if="headInfo.platformType == 1 ">
              联系电话：{{  headInfo.sitePersonphone  || "无" }}
            </div>
            <div class="content-info-middle">
              发文数：{{ headInfo.contentCount  }}
            </div>
            <div class="content-info-middle">
              所属区域：{{ headInfo.areaName || "无" }}
            </div>
            <div class="content-info-middle" v-if="headInfo.platformType == 1">
              ICP备案：{{ headInfo.icpNumber }}
            </div>
            <div v-if="headInfo.platformType !== 1" class="content-info-middle">
              粉丝数：{{ headInfo.fans || "无" }}
            </div>
            <div class="content-info-middle">
              主体类型：{{ headInfo.bodyTypeName || "无" }}
            </div>

            <div v-if="headInfo.platformType !== 1" class="content-info-middle">
              关注数：{{ headInfo.attention || "无" }}
            </div>
            <div class="content-info-middle ">
              <span style="float:left">首页地址：</span>
              <a class="aColor cursor texthidden" style="display:block;max-width:60%;float:left" @click="$main.lookYuanWen(headInfo.homeUrl)" target="_blank"
                 rel="noopener noreferrer">{{ headInfo.homeUrl }}</a>
              <img class="cursor" style="margin-left: 10px;float:left" @click="$main.copyFun(headInfo.homeUrl, '首页地址')" src="@/assets/img/wang/copy-all.png" />
            </div>
          </div>
          <div class="picture">
            <el-image :src="headInfo.headImage" :preview-src-list="[
                    headInfo.headImage,
                  ]">
              <div slot="error" class="image-slot">
                <img src="../../assets/img/zhanwei.png" alt="" srcset="" />
              </div>
            </el-image>
            <!-- <el-image
              class="img"
              :src="headInfo.headImage"
              :preview-src-list="[headInfo.headImage]"
            ></el-image> -->
          </div>
        </div>
      </div>
      <div class="platform-content" v-loading="ifloading">
        <div class="platform-content-header">
          <div class="tag-div" :class="tagDivIndex == 0 ? 'selected-div' : ''" @click="changeIndex(0)">
            正在处置
            <div class="border-btm" v-show="tagDivIndex == 0"></div>
          </div>
          <div class="tag-div" :class="tagDivIndex == 1 ? 'selected-div' : ''" @click="changeIndex(1)">
            处置已完成({{ queryList2.length }})
            <div class="border-btm" v-show="tagDivIndex == 1"></div>
          </div>
          <!-- <div class="editBtn" v-show="tagDivIndex==0">修改</div> -->
        </div>
        <!-- 正在处置 -->
        <div class="platform-content-content" v-show="tagDivIndex == 0">
          <div class="platform-content-content-box" v-for="(item, index) in queryList" :key="index">
            <div class="platform-content-box-header">
              <div class="box-header-title">处理记录{{ index + 1 }}</div>
              <div class="box-header-tag">
                <div class="orangeBtn" @click="carryOut(item)" v-if="!item.content.carryOut">
                  开展处置
                </div>
                <div class="greenBtn m-l" @click="disposeComplete(item)" v-if="
                    item.content.misjudgmentAdjustment || item.content.carryOut
                  ">
                  处置完成
                </div>
                <div class="redBtn m-l" @click="misjudgmentAdjustment(item)" v-if="!item.content.misjudgmentAdjustment">
                  误判更正状态
                </div>
              </div>
            </div>
            <div class="platform-content-box-main">
              <div class="box-main-left">
                <div class="box-main-left-info">
                  <div class="left-info-title">
                    <span>发现异常状态</span>
                    <!-- <div><img src="../../assets/img/zhang/edit.png" alt=""><span>编辑</span></div> -->
                  </div>
                  <div class="left-info-main">
                    异常状态：{{
                      item.content.discoveryAbnormal.abnormalStatusName || "无"
                    }}
                  </div>
                  <div class="left-info-main cursor" :class="{
                      'main-blue':
                        item.content.discoveryAbnormal.abnormalStatus !== 1,
                    }" @click="goList(item)">
                    变更原因：{{
                      item.content.discoveryAbnormal.changeReason || "无"
                    }}
                  </div>
                  <div class="left-info-main">
                    发现方式：{{
                      item.content.discoveryAbnormal.discoveryModeName || "无"
                    }}
                  </div>
                  <div class="left-info-main">
                    发现时间：{{
                      item.content.discoveryAbnormal.discoveryTime || "无"
                    }}
                  </div>
                  <div class="left-info-main" v-if="item.content.discoveryAbnormal.abnormalImgUrl">
                    异常图文：
                    <span @click="doPriveImg(item.content.discoveryAbnormal.abnormalImgUrl)"
                          class="overflowText cur" style="width:50%;color:#1989fa">
                      {{item.content.discoveryAbnormal.abnormalImgUrl || "无"}}
                    </span>
                    <el-image ref="previewImg" v-show="false" style="width: 100px; height: 100px" :src="url" :preview-src-list="srcList">
                      <div slot="error" class="image-slot">
                        <!-- <span>该截图存在问题</span> -->
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <img class="cursor" @click="$main.copyFun(item.content.discoveryAbnormal.abnormalImgUrl, '图文地址')" src="@/assets/img/wang/copy-all.png" />
                  </div>
                </div>
                <!-- <el-image :src="item.content.discoveryAbnormal.abnormalImgUrl" :preview-src-list="[
                    item.content.discoveryAbnormal.abnormalImgUrl,
                  ]">
                  <div slot="error" class="image-slot">
                    <img src="../../assets/img/zhanwei.png" alt="" srcset="" />
                  </div>
                </el-image> -->
              </div>
              <!-- 误判 -->
              <div class="box-main-right" v-if="item.content.misjudgmentAdjustment">
                <div class="box-main-left-info">
                  <div class="left-info-title"><span>误判调整</span></div>
                  <div class="left-info-main">
                    异常状态：{{
                      item.content.misjudgmentAdjustment.abnormalStatus
                    }}
                  </div>
                  <div class="left-info-main">
                    变更原因：{{
                      item.content.misjudgmentAdjustment.changeReason
                    }}
                  </div>
                  <!-- <div class="left-info-main">发现方式：机器研判、人工研判</div> -->
                  <div class="left-info-main">
                    发现时间：{{
                      item.content.misjudgmentAdjustment.changeTime
                    }}
                  </div>
                </div>
              </div>
              <div class="box-main-right" v-if="item.content.carryOut">
                <div class="box-main-left-info">
                  <div class="left-info-title">
                    <span>开展处置</span>
                    <div @click="bianjiFn" v-show="!isbianji">
                      <img src="../../assets/img/zhang/edit.png" alt="" /><span>编辑</span>
                    </div>
                    <div v-show="isbianji">
                      <el-button type="warning" plain size="small" @click="tjBianjiFn(item)">提交</el-button>
                      <el-button type="warning" plain size="small" @click="qxBianjiFn">取消</el-button>
                    </div>
                  </div>
                  <template v-if="isbianji">
                    <div class="dialog-body" style="padding: 0" v-loading="newPlanData.loading">
                      <div class="dialog-body-li">
                        <div class="dialog-body-li-l">
                          <span>处置方式</span>
                        </div>
                        <div class="dialog-body-li-r">
                          <el-select class="w210" v-model="item.content.carryOut.disposeMode" placeholder="请选择" size="small">
                            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="dialog-body-li m-t-16">
                        <div class="dialog-body-li-l">处置内容</div>
                        <div class="dialog-body-li-r w280">
                          <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="item.content.carryOut.disposeContent">
                          </el-input>
                        </div>
                      </div>
                      <div class="dialog-body-li m-t-16">
                        <div class="dialog-body-li-l">处置时间</div>
                        <div class="dialog-body-li-r">
                          <el-date-picker class="w280" v-model="item.content.carryOut.disposeTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                          placeholder="选择日期">
                          </el-date-picker>
                        </div>
                      </div>
                      <div class="dialog-body-li m-t-16">
                        <div class="dialog-body-li-l">相关附件</div>
                        <div class="dialog-body-li-r">
                          <el-upload class="upload-demo" :action="baseURL + actionUrl" :headers="{ Authorization: token }" :on-success="handleAvatarSuccess"
                                     :on-error="$main.handleAvatarError" :before-upload="$main.beforeAvatarUpload" :on-remove="handleChange" :multiple="false"
                                     :limit="1" :file-list="fileList">
                            <el-button size="small" type="primary">点击上传</el-button>
                          </el-upload>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="left-info-main">
                      <div class="w120">处置方式：</div>
                      <div v-html="item.content.carryOut.disposeModeName || '无'"></div>
                    </div>
                    <div class="left-info-main">
                      <div class="w120">处置内容：</div>
                      <div v-html="item.content.carryOut.disposeContent || '无'"></div>
                    </div>
                    <div class="left-info-main">
                      <div class="w120">处置时间：</div>
                      <div v-html="item.content.carryOut.disposeTime || '无'"></div>
                    </div>
                    <div class="left-info-main">
                      <div class="w120">相关附件：</div>
                      <div>
                        <Download :attachment="item.content.carryOut.attachment" v-if="item.content.carryOut.attachment"></Download>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <bitmap v-if="queryList.length == 0"></bitmap>
        </div>
        <!-- 处置已完成 -->
        <div class="platform-content-content1" v-show="tagDivIndex == 1">
          <div class="platform-content-content-box" v-for="(item, index) in queryList2" :key="index">
            <div class="platform-content-box-header">
              <div class="box-header-title">处理记录{{ index + 1 }}</div>
              <!-- <div class="box-header-tag">
                <div class="orangeBtn">开展处置</div>
                <div class="greenBtn">处置完成</div>
                <div class="redBtn">误判更正状态</div>
              </div> -->
            </div>
            <div class="platform-content-box-main">
              <div class="box-main-left">
                <div class="box-main-left-info">
                  <div class="left-info-title"><span>发现异常状态</span></div>
                  <div class="left-info-main">
                    异常状态：{{
                      item.content.discoveryAbnormal.abnormalStatusName || "无"
                    }}
                  </div>
                  <div class="left-info-main">
                    变更原因：{{
                      item.content.discoveryAbnormal.changeReason || "无"
                    }}
                  </div>
                  <div class="left-info-main">
                    发现方式：{{
                      item.content.discoveryAbnormal.discoveryModeName || "无"
                    }}
                  </div>
                  <div class="left-info-main">
                    发现时间：{{
                      item.content.discoveryAbnormal.discoveryTime || "无"
                    }}
                  </div>
                  <div class="left-info-main" v-if="item.content.discoveryAbnormal.abnormalImgUrl">
                    异常图文：<span @click="doPriveImg(item.content.discoveryAbnormal.abnormalImgUrl)" class="overflowText cur" style="width:50%;color:#1989fa">
                      {{item.content.discoveryAbnormal.abnormalImgUrl || "无"}}
                    </span>
                    <img class="cursor" @click="$main.copyFun(item.content.discoveryAbnormal.abnormalImgUrl, '图文地址')" src="@/assets/img/wang/copy-all.png" />
                  </div>
                </div>
                <!-- <img :src="item.content.discoveryAbnormal.abnormalImgUrl" alt="" v-if="item.content.discoveryAbnormal.abnormalImgUrl" /> -->
              </div>
              <div class="box-main-middle" v-if="item.content.misjudgmentAdjustment">
                <div class="box-main-left-info">
                  <div class="left-info-title"><span>误判调整</span></div>
                  <div class="left-info-main">
                    异常状态：{{
                      item.content.misjudgmentAdjustment.abnormalStatus
                    }}
                  </div>
                  <div class="left-info-main">
                    变更原因：{{
                      item.content.misjudgmentAdjustment.changeReason
                    }}
                  </div>
                  <!-- <div class="left-info-main">发现方式：机器研判、人工研判</div> -->
                  <div class="left-info-main">
                    发现时间：{{
                      item.content.misjudgmentAdjustment.changeTime
                    }}
                  </div>
                </div>
              </div>
              <div class="box-main-middle" v-if="item.content.carryOut">
                <div class="box-main-left-info">
                  <div class="left-info-title"><span>开展处置</span></div>
                  <div class="left-info-main">
                    处置方式：{{
                      item.content.carryOut.disposeModeName || "无"
                    }}
                  </div>
                  <div class="left-info-main">
                    处置内容：{{ item.content.carryOut.disposeContent || "无" }}
                  </div>
                  <div class="left-info-main">
                    处置时间： {{ item.content.carryOut.disposeTime || "无" }}
                  </div>
                  <div class="left-info-main">
                    <div>相关附件</div>
                    <div class="m-l-16">
                      <Download :attachment="item.content.carryOut.attachment" v-if="item.content.carryOut.attachment"></Download>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-main-right">
                <div class="box-main-left-info">
                  <div class="left-info-title">
                    <span>处置完成</span>
                  </div>
                  <div class="left-info-main">
                    处置结果：{{
                      item.content.disposeComplete
                        ? item.content.disposeComplete.disposeResultName
                        : "无"
                    }}
                  </div>
                  <div class="left-info-main">
                    处置内容：{{
                      item.content.disposeComplete
                        ? item.content.disposeComplete.disposeContent
                        : "无"
                    }}
                  </div>
                  <!-- <div class="left-info-main main-blue">  
                    核查人员：
                  </div> -->
                  <div class="left-info-main">
                    发现时间：{{
                      item.content.disposeComplete
                        ? item.content.disposeComplete.completeTime
                        : "无"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <bitmap v-if="queryList2.length == 0"></bitmap>
        </div>
      </div>
    </div>
    <!-- 开展处置 -->
    <el-dialog :visible.sync="dialogTableVisible" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">编辑处置</span>
      </div>
      <div class="dialog-body" v-loading="newPlanData.loading" style="padding: 0px 40px 0 40px">
        <div class="dialog-body-li">
          <div class="dialog-body-li-l">
            <span><span class="red">*</span> 处置方式</span>
          </div>
          <div class="dialog-body-li-r">
            <el-select v-model="newPlanData.disposeMode" placeholder="请选择" size="small" style="width: 100%">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="dialog-body-li m-t-big">
          <div class="dialog-body-li-l">
            <span class="red">*</span> 处置内容
          </div>
          <div class="dialog-body-li-r">
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="newPlanData.disposeContent">
            </el-input>
          </div>
        </div>
        <div class="dialog-body-li m-t-big">
          <div class="dialog-body-li-l">
            <span class="red">*</span> 处置时间
          </div>
          <div class="dialog-body-li-r">
            <el-date-picker v-model="newPlanData.disposeTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div class="dialog-body-li m-t-big">
          <div class="dialog-body-li-l">相关附件</div>
          <div class="dialog-body-li-r">
            <el-upload class="upload-demo" :action="baseURL + actionUrl" :headers="{ Authorization: token }" :on-success="handleAvatarSuccess"
                       :on-error="$main.handleAvatarError" :before-upload="$main.beforeAvatarUpload" :on-remove="handleChange" :multiple="false" :limit="1"
                       :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisibleFn" :disabled="newPlanData.loading">取 消
        </el-button>
        <el-button type="primary" :disabled="newPlanData.loading" @click="addFn(null)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 处置完成 -->
    <el-dialog :visible.sync="dialogTableVisible2" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">处置已完成</span>
      </div>
      <div class="dialog-body" v-loading="newPlanData2.loading" style="padding: 0px 40px 0 40px">
        <div class="dialog-body-li">
          <div class="dialog-body-li-l">
            <span><span class="red">*</span> 处置方式</span>
          </div>
          <div class="dialog-body-li-r">
            <el-select v-model="newPlanData2.disposeResult" placeholder="请选择" size="small" style="width: 100%">
              <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="dialog-body-li m-t-big">
          <div class="dialog-body-li-l"><span class="red">*</span>处置内容</div>
          <div class="dialog-body-li-r">
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="newPlanData2.disposeContent">
            </el-input>
          </div>
        </div>
        <div class="dialog-body-li m-t-big">
          <div class="dialog-body-li-l"><span class="red">*</span>处置时间</div>
          <div class="dialog-body-li-r">
            <el-date-picker v-model="newPlanData2.completeTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisibleFn" :disabled="newPlanData.loading">取 消
        </el-button>
        <el-button type="primary" :disabled="newPlanData.loading" @click="addFn2">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="错误取证" :visible.sync="imgVisible">
      <el-image :src="imageUrl" fit='contain' style="width:100%;height:100%;"></el-image>
      <el-image ref="previewImg" v-show="false" style="width: 100px; height: 100px" :src="url" :preview-src-list="srcList">
      </el-image>
      <span @click="clickImg">点我111111</span>
    </el-dialog>
  </div>
</template>

<script>
import Download from "./components/download.vue";
import bitmap from "../../components/bitmap.vue";
import { detail, getList, account } from ".././../api/platformaccount";
import Breadcrumb from "@/components/breadcrumb.vue";
import { Dictionaries } from "../../api/platformaccount/index";
import {
  queryDisposeRecordList,
  carryOut,
  misjudgmentAdjustment,
  disposeComplete,
} from "../../api/Problem/index";
export default {
  components: { Breadcrumb, bitmap, Download },
  data() {
    return {
      token: "Bearer " + window.localStorage.getItem("token"),
      baseURL: window.localStorage.getItem("baseURL"),
      actionUrl: window.localStorage.getItem('actionUrl'),
      fileList: [], // 上传文件的数组
      ifloading: true, // 进页面时候加载状态
      isbianji: false,
      headInfo: { messageTypeNameList: [] }, // 头部详情页
      routerList: ["问题平台", "详情"], //面包屑
      selTag: "", //选择标签
      infoLabelList: [],
      tagDivIndex: 0, //选项索引
      queryObj: {}, // 保存传进来的参数
      queryList: [], // 正在处置列表
      queryList2: [], // 处置已完成列表
      dialogTableVisible: false,
      dialogTableVisible2: false,
      newPlanData: {
        //弹出框
        // attachment: {}, //上传文件的url
        disposeTime: "", //处置时间
        disposeMode: null, // 处置方式
        disposeContent: "", // 处置内容
      },
      newPlanData2: {
        //弹出框
        disposeResult: null,
        disposeContent: "", //处置内容
        completeTime: "", // 处置时间
      },
      options2: [], // 开展处置里面的
      options3: [], // 处置结果里面的
      value: "",
      textarea: "",
      value1: [],
      item: {},
      item2: {},
      imageUrl: '',
      imgVisible: false,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: ['https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',]
    };
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params.isChuzhi) {
      const { accountId, platformType } = params;
      this.queryObj = { accountId, platformType };
      this.tagDivIndex = 1
    } else if (params) {
      // const { item } = params; 
      // const { accountId, platformType } = item;
      // this.queryObj = { accountId, platformType };

      const { item } = params;
      console.log(item);
      if (item) {//不是从首页进来
        const { accountId, platformType } = item;
        this.queryObj = { accountId, platformType };
      } else {//从首页进来
        const { accountId, platformType } = params;
        this.queryObj = { accountId, platformType };
      }
    }
    this.getdetail(); // 获取详情
    this.queryDisposeRecordList(); // 开展时候的列表
    this.Dictionaries(); // 字典表
    this.getList();
  },
  mounted() {
  },
  methods: {
    clickImg() {
      console.log(this.$refs.previewImg);
      this.$refs.previewImg.showViewer = true
    },
    // 异常图文预览
    doPriveImg(url) {
      console.log(url);
      console.log(this.$refs.previewImg);
      // this.imgVisible = true
      // this.url = url
      this.srcList = [url]
      this.$refs.previewImg[0].showViewer = true
    },
    dialogTableVisibleFn() {
      this.newPlanData = {
        disposeTime: "", //处置时间
        disposeMode: null, // 处置方式
        disposeContent: "", // 处置内容
      };
      (this.newPlanData2 = {
        //弹出框
        disposeResult: null,
        disposeContent: "", //处置内容
        completeTime: "", // 处置时间
      }),
        (this.fileList = []);
      this.dialogTableVisible2 = false;
      this.dialogTableVisible = false;
    },
    // 上传文件成功
    handleAvatarSuccess(res) {
      const obj =
        res.code == 200
          ? { message: "上传成功！", type: "success" }
          : { message: "上传失败" + res.msg, type: "error" };
      this.$message(obj);
      if (res.code == 200) this.fileList.push(res.data);
    },
    // 控制上传列表(删除)
    handleChange(fileList) {
      this.fileList.forEach((x, y) => {
        if (fileList.uid == x.uid) {
          this.fileList.splice(y, 1);
        }
      });
    },
    async getdetail() {
      const { accountId, platformType } = this.queryObj;
      const Res = await detail({
        accountId,
        platformType,
      });
      if (Res.data.code == 200) {
        this.headInfo = Res.data.data;
      }
    },
    // 点击去向list
    goList({
      accountId,
      platformType,
      content: {
        discoveryAbnormal: { abnormalStatus },
      },
    }) {
      // const {messageTypes} = this.headInfo
      this.$main.routeResolve("/harmful", {
        accountId,
        platformType,
        abnormalStatus,
        pageType: 2,
      });
    },
    back() {
      this.$router.push("/Problem");
    },
    qxBianjiFn() {
      this.isbianji = false;
    },
    tjBianjiFn(item) {
      const { attachment, disposeTime, disposeMode, disposeContent } =
        item.content.carryOut;
      this.item = item;
      this.addFn({
        attachment,
        disposeTime,
        disposeMode,
        disposeContent,
      });
      this.qxBianjiFn();
    },
    bianjiFn() {
      this.isbianji = true;
    },
    // 列表标签 方法
    async ChangeFn(item, e) {
      const { accountId, platformType, labelIds } = item;
      const Res = await account({
        labelIds: labelIds ? labelIds + "," + e : e,
        accountId,
        platformType,
      });
      const { code } = Res.data;
      if (code == 200) {
        this.$message({
          message: `修改标签成功`,
          type: "success",
        });
      } else {
        this.$message.error("修改失败");
      }
      // if(this.$main.ifRes(Res)) return
    },
    // 标签列表
    async getList() {
      const Res = await getList({});
      const {
        data: { list },
      } = Res.data;
      this.infoLabelList = list.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    },
    //  字典表
    async Dictionaries() {
      const Res = await Dictionaries("dispose_mode");
      const { data } = Res.data;
      this.options2 = data.map((v) => ({ label: v.label, value: v.value / 1 }));
      const Res2 = await Dictionaries("dispose_result");
      this.options3 = Res2.data.data.map((v) => ({
        label: v.label,
        value: v.value,
      }));
    },
    // 开展时候的列表
    async queryDisposeRecordList() {
      // 处置状态：0-未处置，1-处置中，2-已处置今天 10:12
      try {
        this.queryObj.disposeStatus = 1;
        const Res = await queryDisposeRecordList(this.queryObj);
        const { data, code } = Res.data;
        if (code == 200) this.queryList = data || [];
        this.ifloading = false;
        this.queryObj.disposeStatus = 2;
        const Res2 = await queryDisposeRecordList(this.queryObj);
        if (Res2.data.code == 200) this.queryList2 = Res2.data.data || [];
      } catch {
        this.ifloading = false;
      }
    },
    // 开展处置
    async carryOut(item) {
      this.dialogTableVisible = true;
      this.item = item;
    },
    // 误判调整
    async misjudgmentAdjustment(item) {
      const { id } = item;
      const Res = await misjudgmentAdjustment(id);
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("误判调整成功");
        this.queryDisposeRecordList();
      }
    },
    // 处置完成
    async disposeComplete(item) {
      this.item2 = item;
      this.dialogTableVisible2 = true;
    },
    async addFn(Keys) {
      let { newPlanData, item, fileList } = this;
      if (!Keys) {
        if (
          newPlanData.disposeMode == null ||
          newPlanData.disposeMode == undefined
        ) {
          this.$message.warning("处置方式不能为空！");
          return;
        }
        if (!newPlanData.disposeContent) {
          this.$message.warning("处置内容不能为空!");
          return;
        }
        if (!newPlanData.disposeTime) {
          this.$message.warning("处置时间不能为空！");
          return;
        }
      }
      if (Keys) {
        if (Keys.disposeMode == null || Keys.disposeMode == undefined) {
          this.$message.warning("处置方式不能为空！");
          return;
        }
        if (!Keys.disposeContent) {
          this.$message.warning("处置内容不能为空!");
          return;
        }
        if (!Keys.disposeTime) {
          this.$message.warning("处置时间不能为空！");
          return;
        }
      }

      newPlanData = Keys ? Keys : newPlanData;
      const { id } = item;
      const obj = {
        id,
        content: {
          carryOut: newPlanData,
        },
      };
      // 判断有无上传文件
      if (fileList && fileList.length > 0) {
        const { name, url } = fileList[0];
        obj.content.carryOut = {
          ...obj.content.carryOut,
          attachment: { name, url },
        };
      }
      const Res = await carryOut(obj);
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("开展处置成功！");
        this.queryDisposeRecordList();
        this.dialogTableVisible = false;
        this.dialogTableVisibleFn();
      }
    },
    async addFn2() {
      const { newPlanData2, item2 } = this;
      const { id } = item2;
      if (
        newPlanData2.disposeResult == null ||
        newPlanData2.disposeResult == undefined
      ) {
        this.$message.warning("处置方式不能为空！");
        return;
      }
      if (!newPlanData2.disposeContent) {
        this.$message.warning("处置内容不能为空!");
        return;
      }
      if (!newPlanData2.completeTime) {
        this.$message.warning("处置时间不能为空！");
        return;
      }
      const obj = {
        id,
        content: {
          disposeComplete: newPlanData2,
        },
      };
      const Res = await disposeComplete(obj);
      const { code } = Res.data;
      if (code == 200) {
        this.$message.success("处置完成！");
        this.queryDisposeRecordList();
        this.dialogTableVisible2 = false;
        this.dialogTableVisibleFn();
      }
    },
    // 改变选项索引
    changeIndex(index) {
      this.tagDivIndex = index;
    },
    //跳转详情
    toDetail(inx, item) {
      // 1:处置详情 跳详情页
      // 2：查看详情 平台台账详情
      // 3：内容信息 跳发布内容列表页
      // 4: 有害信息 跳向有害信息列表
      const routeObj = {
        1: { path: "/questionDetail", query: { item } },
        2: { path: "/accountDetail", query: item },
        3: { path: "/publishingcontent", query: { ...item, pageType: 1 } },
        4: { path: "/harmful", query: { ...item, pageType: 1 } },
      };
      const { path, query } = routeObj[inx];
      this.$main.routeResolve(path, query);
    },
  },
};
</script>
<style>
/* //最外层div,修改背景色，边框 */
.el-popover.monitor-yt-popover {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 0 16px 0;
  /* border-color: #146ebd; */
}
/* //修改title颜色 */
.monitor-yt-popover .el-popover__title {
  color: white;
}
/* //修改下面的小三角，属性名根据组件的placement位置做相应修改 */
.monitor-yt-popover .popper__arrow::after {
  border-right-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
<style lang="scss" scoped>
::v-deep {
  .el-image {
    // min-height: 300px;
    margin: 0 auto;
  }
}
.file-box {
  display: flex;
  align-items: center;
  height: 51px;
  min-width: 170px;
  margin: 0px 30px 16px 0px;
  background: #f5f6fa;
  border-radius: 4px;
  box-sizing: border-box;
  padding-right: 40px;
  cursor: pointer;
  .excel {
    height: 35px;
    width: 30px;
    margin: 0px 8px;
  }
  .fill-mame {
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
    span:nth-child(1) {
      height: 17px;
      line-height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    span:nth-child(2) {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
  }
  .fill-del {
    height: 16px;
    width: 16px;
    margin: 0px 16px 0px 14px;
    cursor: pointer;
  }
}
.detail-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  .detail-header {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    display: flex;
    justify-content: start;
    img {
      width: 38px;
      height: 38px;
      margin: 9px 15px 9px 23px;
      cursor: pointer;
    }
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 56px;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
  }
  .page {
    padding-top: 16px;
    .platform-info {
      width: 100%;
      height: 352px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 16px;
      padding: 32px 40px;
      p {
        width: 80%;
        height: 28px;
        // font-size: 20px;
        // font-family: PingFangSC-Medium, PingFang SC;
        // font-weight: 500;
        // color: #333333;
        line-height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 800;
        color: #333333;
      }
      .platform-info-middle {
        width: 100%;
        display: flex;
        justify-content: start;
        margin: 24px 0 16px 0;

        .platform-info-middle-left {
          display: flex;
          justify-content: start;
          width: 50%;
          .greedtag {
            width: 66px;
            height: 24px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            background-color: #02bc7c;
            line-height: 24px;
            margin-top: 4px;
            padding-left: 4px;
            text-align: center;
          }
          .tag {
            width: 120px;
            height: 24px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            background-color: #ff4b1f;
            line-height: 24px;
            margin-top: 4px;
            // padding-left: 4px;
            text-align: center;
            // padding: 10px;
          }
          .sel-tag {
            display: flex;
            justify-content: start;
            width: 136px;
            margin: 0 24px;
            span {
              width: 28px;
              height: 32px;
              margin-right: 6px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 32px;
            }
            .sel-tag-sel {
              width: 102px;
              ::v-deep {
                .el-input__inner {
                  height: 32px;
                }
                .el-input__icon {
                  line-height: 32px;
                }
                .el-input__prefix,
                .el-input__suffix {
                  height: 32px;
                }
                .el-input.is-focus .el-input__inner {
                  caret-color: #f58030;
                  border: 1px solid #f58030 !important;
                  transition: all 0.3s;
                }
              }
            }
          }
          .disposal {
            width: 112px;
            height: 32px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
          }
        }
        .platform-info-middle-right {
          display: flex;
          justify-content: end;
          width: 50%;

          .detail-middle {
            margin: 0 24px;
          }
          .harmful-info {
            color: #e60b1e;
          }
          div {
            height: 32px;
            line-height: 30px;
            font-size: 14px;
            color: #333333;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            cursor: pointer;
            img {
              vertical-align: middle;
              margin-right: 7px;
            }
            span {
              vertical-align: middle;
            }
          }
        }
      }
      .platform-info-bottom {
        display: flex;
        justify-content: start;
        width: 100%;
        height: 188px;
        background: #f4f5fa;
        border-radius: 4px;
        padding: 32px;
        .content-info {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .content-info-middle {
            margin-bottom: 32px;
            width: 33%;
            height: auto;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
        }
        .picture {
          width: 111px;
          height: 108px;
          .el-image {
            width: 100%;
            height: 100%;
          }
          .img {
            width: 111px;
            height: 108px;
            margin: 8px 8px 8px 250px;
          }
        }
      }
    }
    .platform-content {
      width: 100%;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 16px;
      .platform-content-header {
        display: flex;
        justify-content: start;
        width: 100%;
        height: 54px;
        padding-left: 8px;
        border-bottom: 1px solid #cccccc;
        .tag-div {
          position: relative;
          margin: 0 32px;
          height: 54px;
          line-height: 54px;
          cursor: pointer;
          .border-btm {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            width: 64px;
            height: 2px;
            background: #ed731f;
          }
        }
        .editBtn {
          width: 60px;
          height: 36px;
          background: #f58030;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 36px;
          text-align: center;
          margin: 10px 45px 10px 1210px;
        }
      }
      .platform-content-content {
        padding: 24px 40px;
        .platform-content-content-box {
          width: 100%;
          min-height: 326px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e4e6ec;
          margin-bottom: 16px;
          .platform-content-box-header {
            width: 100%;
            height: 56px;
            background: #f4f5fa;
            border-radius: 4px 4px 0px 0px;
            border-bottom: 1px solid #e4e6ec;
            .box-header-title {
              width: 200px;
              height: 56px;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 56px;
              margin-left: 24px;
              float: left;
            }
            .box-header-tag {
              display: flex;
              justify-content: end;
              width: 500px;
              float: right;
              .orangeBtn {
                width: 88px;
                height: 32px;
                margin-top: 12px;
                background: #fff1e8;
                border-radius: 4px;
                border: 1px solid #f58030;
                color: #f58030;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
              }
              .greenBtn {
                width: 88px;
                height: 32px;
                margin: 12px 16px 0 14px;
                background: #dff7ef;
                border-radius: 4px;
                border: 1px solid #0fbf82;
                color: #0fbf82;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
              }
              .redBtn {
                width: 116px;
                height: 32px;
                margin-top: 12px;
                margin-right: 16px;
                background: #fff2f3;
                border-radius: 4px;
                border: 1px solid #e60b1e;
                color: #e60b1e;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
              }
            }
          }
          .platform-content-box-main {
            width: 100%;
            min-height: 270px;
            height: auto;
            display: flex;
            .box-main-left {
              width: 50%;
              height: 100%;
              display: flex;
              justify-content: space-between;
              // border-right: 1px solid #e4e6ec;
              .el-image {
                width: 277px;
                height: 271px;
              }
              .box-main-left-info {
                // width: 100%;
                margin: 32px 170px 32px 32px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                .left-info-title {
                  display: flex;
                  font-size: 16px;
                  font-weight: 600;
                  color: #333333;
                  line-height: 22px;
                  margin-bottom: 32px;
                  div {
                    font-weight: 500;
                    color: #f58030;
                    line-height: 18px;
                    margin-left: 24px;
                    cursor: pointer;
                    img {
                      width: 14px;
                      height: 14px;
                      vertical-align: middle;
                      margin: 0 4px 0 0;
                    }
                    span {
                      vertical-align: middle;
                    }
                  }
                }
                .left-info-main {
                  width: 100%;
                  font-weight: 400;
                  color: #333333;
                  line-height: 20px;
                  margin-bottom: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  img {
                    width: 18px;
                    height: 18px;
                    margin: 0;
                  }
                }
              }
              img {
                width: 160px;
                height: 160px;
                margin: 55px 60px;
              }
            }
            .box-main-right {
              width: 50%;
              height: 100%;
              border-left: 1px solid #e4e6ec;
              .box-main-left-info {
                width: 100%;
                margin: 32px 170px 32px 32px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                .left-info-title {
                  display: flex;
                  font-size: 16px;
                  font-weight: 600;
                  color: #333333;
                  line-height: 22px;
                  margin-bottom: 32px;
                  div {
                    font-weight: 500;
                    color: #f58030;
                    line-height: 18px;
                    margin-left: 24px;
                    cursor: pointer;
                    img {
                      vertical-align: middle;
                      margin-right: 4px;
                    }
                    span {
                      vertical-align: middle;
                    }
                  }
                }
                .left-info-main {
                  width: 100%;
                  font-weight: 400;
                  color: #333333;
                  line-height: 20px;
                  margin-bottom: 24px;
                  display: flex;
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }
      .platform-content-content1 {
        padding: 24px 40px;
        .platform-content-content-box {
          width: 100%;
          height: auto;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e4e6ec;
          margin-bottom: 16px;
          .platform-content-box-header {
            width: 100%;
            height: 56px;
            background: #f4f5fa;
            border-radius: 4px 4px 0px 0px;
            border-bottom: 1px solid #e4e6ec;
            .box-header-title {
              width: 200px;
              height: 56px;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 56px;
              margin-left: 24px;
              float: left;
            }
            .box-header-tag {
              display: flex;
              justify-content: end;
              width: 500px;
              float: right;
              .orangeBtn {
                width: 88px;
                height: 32px;
                margin-top: 12px;
                background: #fff1e8;
                border-radius: 4px;
                border: 1px solid #f58030;
                color: #f58030;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
              }
              .greenBtn {
                width: 88px;
                height: 32px;
                margin: 12px 16px 0 16px;
                background: #dff7ef;
                border-radius: 4px;
                border: 1px solid #0fbf82;
                color: #0fbf82;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
              }
              .redBtn {
                width: 116px;
                height: 32px;
                margin-top: 12px;
                margin-right: 16px;
                background: #fff2f3;
                border-radius: 4px;
                border: 1px solid #e60b1e;
                color: #e60b1e;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
              }
            }
          }
          .platform-content-box-main {
            width: 100%;
            height: auto;
            display: flex;
            .box-main-left {
              width: 606px;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              .box-main-left-info {
                width: 100%;
                margin: 32px 50px 32px 32px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                .left-info-title {
                  display: flex;
                  font-size: 16px;
                  font-weight: 600;
                  color: #333333;
                  line-height: 22px;
                  margin-bottom: 32px;
                }
                .left-info-main {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  width: 100%;
                  font-weight: 400;
                  color: #333333;
                  line-height: 20px;
                  margin-bottom: 24px;
                  span {
                    width: 400px !important;
                  }
                  img {
                    width: 18px;
                    height: 18px;
                    margin: 0;
                  }
                }
              }
              img {
                width: 160px;
                height: 160px;
                margin: 55px 50px;
              }
            }
            .box-main-middle {
              width: 475px;
              // height: 100%;
              border-left: 1px solid #e4e6ec;
              border-right: 1px solid #e4e6ec;
              .box-main-left-info {
                width: auto;
                margin: 32px 50px 32px 32px;

                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                .left-info-title {
                  display: flex;
                  font-size: 16px;
                  font-weight: 600;
                  color: #333333;
                  line-height: 22px;
                  margin-bottom: 32px;
                }
                .left-info-main {
                  width: 100%;
                  font-weight: 400;
                  color: #333333;
                  line-height: 20px;
                  margin-bottom: 24px;
                  display: flex;
                  justify-content: flex-start;
                }
              }
            }
            .box-main-right {
              width: 481px;
              height: 100%;
              .box-main-left-info {
                width: auto;
                margin: 32px 170px 32px 32px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                .left-info-title {
                  display: flex;
                  font-size: 16px;
                  font-weight: 600;
                  color: #333333;
                  line-height: 22px;
                  margin-bottom: 32px;
                }
                .left-info-main {
                  width: 100%;
                  font-weight: 400;
                  color: #333333;
                  line-height: 20px;
                  margin-bottom: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.selected-div {
  color: #ed731f;
}
.main-blue {
  color: #2e59ec !important;
}
.dialog-body {
  .dialog-body-li {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .dialog-body-li-l {
      width: 100px;
      span {
        margin-top: 50px;
      }
    }
    .dialog-body-li-r {
      // width: calc(100% - 100px);
      width: auto;
    }
  }
}
.popover {
  // width: 300px;
  // height: 300px;
  // background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content:space-evenly
  .popover-type {
    cursor: pointer;
    width: 114px;
    min-height: 28px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 4px 10px;
    background: #ff4b1f;
    border-radius: 1px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px;
  }
}
.type {
  cursor: pointer;
  width: 114px;
  min-height: 28px;
  text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding: 4px 10px;
  background: #ff4b1f;
  border-radius: 1px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>